/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// exports.onInitialClientRender = () => {
//   require('./src/styles/layout.css')
// }
// Load Montserrat typeface
require('typeface-montserrat')

// Load Open-Sans typeface
require('typeface-open-sans')

// const React = require('react')
// const Layout = require('./src/components/layout').default

// exports.wrapPageElement = ({element, props}) => {
//   // props provide same data to Layout as Page element will get
//   // including location, data, etc - you don't need to pass it
//   return <Layout {...props}>{element}</Layout>
// }
